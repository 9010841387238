import React from "react"
import * as Chakra from "@chakra-ui/react"
import { ScrollFadeIn } from "../animation-helpers/animation-state"

interface ProjectEmbedProps {
  content: any
}

const ProjectEmbed = React.forwardRef(
  (props: ProjectEmbedProps, ref) => {
    return (
      <Chakra.Box
        ref={ref}
        width="100%"
        display="flex"
        position="relative"
        alignItems="center"
        flexDirection="column"
        backgroundColor="transparent"
      >
        <Chakra.Box
          width="100%"
          display="flex"
          maxWidth="1800px"
          alignItems="center"
          marginLeft="auto"
          marginRight="auto"
          paddingLeft="40px"
          paddingRight="40px"
          justifyContent="center"
          css={{
            "@media (max-width: 767px)": {
              paddingLeft: "20px",
              paddingRight: "20px",
            },
          }}
          className="project-block"
          as={ScrollFadeIn}
        >
          <Chakra.AspectRatio ratio={16/9} width="100%">
            <Chakra.Box as="iframe" width="100%" {...(props || {})} />
          </Chakra.AspectRatio>
        </Chakra.Box>
      </Chakra.Box>
    )
  }
)

ProjectEmbed.pressless = {
  name: "Project Embed",
  attributes: {
    src: {
      name: "Src",
      type: "PlainText",
    },
    allowFullScreen: {
      name: "Allow Full Screen",
      type: "Boolean"
    }
  },
}
export default ProjectEmbed
